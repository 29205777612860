<mat-drawer-container autosize [class]="dark ? 'dark-theme' : 'light-theme'">
  <mat-drawer #drawer [opened]="!minimized && !isDatasetVersionPreview" [mode]="minimized ? 'over' : 'side'" [disableClose]="!minimized && !isDatasetVersionPreview">
    <i data-id="closeButton" class="close al-icon al-ico-dialog-x sm-md" *ngIf="minimized" (click)="drawer.close()"></i>
    <div class="list-container">
      <sm-selectable-filter-list
        *ngIf="plotsList !== undefined"
        checkAllIcon="al-ico-show"
        placeholder="Find plots"
        [list]="plotsList"
        [checkedList]="selectedMetrics"
        [searchTerm]="searchTerm$ | async"
        (itemSelect)="metricSelected($event)"
        (hiddenChanged)="hiddenListChanged($event)"
        (searchTermChanged)="searchTermChanged($event)"
      >
      </sm-selectable-filter-list>
    </div>
  </mat-drawer>
  <mat-drawer-content class="overflow-hidden">
    <div class="graphs-container">
      <div class="hover-button" *ngIf="minimized">
        <button
          class="btn btn-secondary"
          (click)="drawer.open()"
          smTooltip="Toggle Graphs"
        >
          <i class="al-icon al-ico-eye-outline" data-id="eyeIcon"></i>
        </button>
      </div>
      <sm-experiment-graphs
        [class.dark-theme]="dark"
        [disableResize]="isDatasetVersionPreview"
        [isGroupGraphs]="true"
        [hiddenList]="selectedMetrics"
        [metrics]="graphs"
        [legendStringLength]="minimized ? 14 : 19"
        [minimized]="minimized"
        [splitSize]="splitSize$ | async"
        [isDarkTheme]="dark"
        (resetGraphs)="resetMetrics()"
        (createEmbedCode)="createEmbedCode($event)"
        [hoverMode]="'x unified'"
      >
      </sm-experiment-graphs>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
