/**
 * projects
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 2.14
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */



export interface ProjectsGetTaskParentsRequest {
    /**
     * The list of projects which task parents are retieved. If not passed or empty   then all the projects are searched
     */
    projects?: Array<string>;
    /**
     * Return parents for tasks in the specified state. If Null is provided, parents   for all task states will be returned.
     */
    tasks_state?: ProjectsGetTaskParentsRequest.TasksStateEnum;
    /**
     * If set to \'true\' and the projects field is not empty then the result includes   tasks parents from the subproject tasks
     */
    include_subprojects?: boolean;
}
export namespace ProjectsGetTaskParentsRequest {
    export type TasksStateEnum = 'active' | 'archived';
    export const TasksStateEnum = {
        Active: 'active' as TasksStateEnum,
        Archived: 'archived' as TasksStateEnum
    }
}
