<div *ngIf="hiddenList?.length === 0 || noGraphs && !(multipleSingleValueData?.data.length > 0) && !(singleValueData?.length > 0)" class="no-output" >
  <i class="icon no-output-icon" [ngClass]="isDarkTheme ? 'i-no-plots-dark' : 'i-no-plots'"></i>
  <h4>NO CHART DATA</h4>
</div>

<div #allMetrics class="metrics-section" [class.hidden]="hiddenList?.length === 0">
  <div class="d-flex align-items-center justify-content-center summary-container" *ngIf="singleValueData?.length>0 && hiddenList.includes(singleValueChartTitle)">
    <sm-single-value-summary-table
      *ngIf="exportForReport; else: noEmbedCode"
      [data]="singleValueData"
      [experimentName]="experimentName"
      class="single-value-summary-section"
      (createEmbedCode)="creatingEmbedCode(null, $event)"
    ></sm-single-value-summary-table>
    <ng-template #noEmbedCode>
      <sm-single-value-summary-table
        [data]="singleValueData"
        [experimentName]="experimentName"
        class="single-value-summary-section"
      ></sm-single-value-summary-table>
    </ng-template>
  </div>
    <div
      *ngIf="multipleSingleValueData?.data.length > 0"
      class="d-flex align-items-center justify-content-center summary-container"
    >
      <sm-single-graph
        #singleValueGraph
        class="w-100 single-value-summary-section"
        [graphsNumber]="1"
        [legendStringLength]="legendStringLength"
        [chart]="multipleSingleValueData"
        [id]="'singleValues'"
        [isCompare]="isCompare"
        [hoverMode]="hoverMode"
        [isDarkTheme]="isDarkTheme"
        [showLoaderOnDraw]="showLoaderOnDraw"
        [identifier]="generateIdentifier(multipleSingleValueData)"
        [exportForReport]="exportForReport"
        (createEmbedCode)="creatingEmbedCode(null, $event)"
        (hoverModeChanged)="hoverModeChanged.emit($event)"
      ></sm-single-graph>
  </div>
  <div [class.all-metrics]="graphList?.length > 0"
       [class.row]="!isGroupGraphs"
  >
    <div *ngFor="let metric of (graphList); trackBy: trackByFn" class="metric-group-container less-padding"
         #metricGroup
         [class.two-in-a-row]="!isGroupGraphs && graphList.length > 1 && isWidthBigEnough()"
         [style.height.percent]=""
         [class.hidden]="inHiddenList(metric)"
    >
      <div [id]="experimentGraphidPrefix + metric" class="graph-id">
        <div>
          <div class="metric-group-header" *ngIf="isGroupGraphs" [class.disable-resize]="disableResize">{{metric}}</div>
          <div class="single-graph">
<!--          <div [class.row]="isGroupGraphs"-->
<!--               [class.single-graph]="graphsData[metric].length === 1">-->
            <div
              *ngFor="let chartItem of graphsData[metric]; trackBy: trackByIdFn"
              #singleGraphContainer
              mwlResizable
              class="single-graph-container"
              [validateResize]="validateResize"
              [enableGhostResize]="!disableResize"
              [ghostElementPositioning]="'absolute'"
              [id]="chartItem.id"
              (resizing)="onResizing($event);"
              (resizeEnd)="sizeChanged($event)"
              (resizeStart)="resizeStarted(metricGroup, singleGraphContainer)"
            >
              <sm-single-graph
                *ngIf="chartItem.visible"
                [class.less-padding]="isGroupGraphs"
                [class.two-in-a-row]="isGroupGraphs && chartItem?.data[0]?.type !== 'table' && graphsData[metric].length > 1 && isWidthBigEnough()"
                [graphsNumber]="graphsData[metric].length"
                [legendStringLength]="legendStringLength"
                [chart]="chartItem"
                [id]="chartItem.id"
                [isCompare]="isCompare"
                [hoverMode]="hoverMode"
                [isDarkTheme]="isDarkTheme"
                [showLoaderOnDraw]="showLoaderOnDraw"
                [legendConfiguration]="legendConfiguration"
                [smoothWeight]="smoothWeight"
                [smoothType]="smoothType"
                [xAxisType]="xAxisType"
                [height]="height"
                [width]="width"
                [moveLegendToTitle]="checkIfLegendToTitle(chartItem)"
                [identifier]="generateIdentifier(chartItem)"
                [exportForReport]="exportForReport"
                (createEmbedCode)="creatingEmbedCode(chartItem, $event)"
                (hoverModeChanged)="hoverModeChanged.emit($event)"
              ></sm-single-graph>
              <div *ngIf="!disableResize"
                class="resize-handle-right"
                mwlResizeHandle
                [resizeEdges]="{ right: true }">
                <div class="right-line"></div>
                <div class="right-handle"></div>
              </div>
              <div *ngIf="!disableResize"
                class="resize-handle-bottom"
                mwlResizeHandle
                [resizeEdges]="{ bottom: true }">
                <div class="bottom-line"></div>
                <div class="bottom-handle"></div>
              </div>
              <div class="resize-overlay-text"></div>
              <div class="resize-overlay" [class.active]="activeResizeElement && activeResizeElement !== singleGraphContainer"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

