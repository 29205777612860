<div smClickStopPropagation class="head">
  <i (click)="goBack.emit()" data-id="Back Button" class="al-icon sm-md al-ico-back pointer m-auto" smClickStopPropagation></i>
  <h3>SELECT HYPERPARAMETERS TO DISPLAY</h3>
</div>
<sm-grouped-checked-filter-list
  smClickStopPropagation
  [itemsList]="hyperParams"
  [selectedItemsList]="metricsCols"
  (selectedItems)="toggleParamToDisplay($event)"
  (clearSelection)="clearSelection.emit()"
></sm-grouped-checked-filter-list>
