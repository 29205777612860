<sm-menu
  #smMenu
  [iconClass]="menuHeader() ? 'al-icon al-ico-dropdown-arrow' : 'al-icon al-ico-settings al-color pointer create-new-icon ' + (disabled() ? 'pointer-events-none blue-500' : 'blue-300')"
  [header]="menuHeader()"
  [smMenuClass]="darkTheme() ? 'dark-theme dark custom-columns' : 'light-theme custom-columns'"
  data-id="CustomizeColumn"
  [buttonTooltip]="menuTooltip() ?? topTitle()"
  (mouseup)="!disabled() && getMetricsToDisplay.emit()"
  (menuClosed)="customColumnMode.set(customColumnModeEnum.Standard)"
  [style.pointer-events]="disabled() ? 'none' : 'initial'"
  >
  @if (!customColumnMode()) {
    <div (click)="$event.stopPropagation()" [class.dark]="darkTheme()">
      <sm-custom-columns-list
        [tableCols]="tableCols()"
        [isLoading]="isLoading()"
        [selectable]="selectable()"
        [menuTitle]="topTitle() | uppercase"
        (removeColFromList)="removeColFromList.emit($event)"
        (selectedTableColsChanged)="selectedTableColsChanged.emit($event); !selectable() && smMenu.trigger.closeMenu()"
        >
      </sm-custom-columns-list>
      <div [class.loading]="isLoading()"
           [class.loaded]="!isLoading()">
        @if (sections().length > 1) {
          <div class="sm-menu-header">{{sectionsTitle()}}</div>
        }
        <div class="custom-column-buttons">
          <div class="add-button metrics-button"
            [class.only-one-section]="sections().length === 1"
            [class.disabled]="!sections()[0]?.options?.length"
            smClickStopPropagation
            (click)="$event.stopPropagation(); sections()[0]?.options?.length && customColumnMode.set(customColumnModeEnum.Metrics)"
            ><i class="al-icon al-ico-plus sm-md me-1"></i><span data-id="Metric Button" class="caption">{{sections()[0]?.title ?? sections()[0]?.name | uppercase}}</span>
          </div>
          @if (sections()[1]) {
            <div class="add-button metrics-button"
              smClickStopPropagation
              [class.disabled]="!hasSecondSection()"
              (click)="$event.stopPropagation(); hasSecondSection() && customColumnMode.set(customColumnModeEnum.HyperParams)"
              ><i class="al-icon al-ico-plus sm-md me-1"></i><span data-id="Hyperparameters Button" class="caption">{{sections()[1]?.title ?? sections()[1]?.name | uppercase}}</span>
            </div>
          }
        </div>
      </div>
    </div>
  }
  @if (customColumnMode() === customColumnModeEnum.Metrics) {
    <ng-container *ngTemplateOutlet="sections()[0].template; context: {$implicit: sections()[0]}"></ng-container>
  }
  @if (customColumnMode() === customColumnModeEnum.HyperParams) {
    <ng-container *ngTemplateOutlet="sections()[1].template; context: {$implicit: sections()[1]}"></ng-container>
  }
</sm-menu>
