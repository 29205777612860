<div class="navbar-header-container">
  @for (route of contextNavbar(); track route.header) {
    <sm-navbar-item
      *smCheckPermission="route.permissionCheck"
      direction="bottom"
      [attr.data-id]="route?.id ?? route.header"
      [header]="route.header"
      [active]="route.isActive"
      [routerLink] = "route.link"
      [subHeader]="archivedMode() && route.subHeader"
      (click)="setFeature(route.featureName ?? route.header)"
    ></sm-navbar-item>
  }
</div>
