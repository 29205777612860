import {Component} from '@angular/core';

@Component({
  selector: 'sm-header-user-menu-actions',
  templateUrl: './header-user-menu-actions.component.html',
  styleUrls: ['./header-user-menu-actions.component.scss']
})
export class HeaderUserMenuActionsComponent {

  constructor() {}
}
