import {Injectable} from '@angular/core';

@Injectable()
export class ExperimentsMenuEffects {

  constructor() {
  }


}
